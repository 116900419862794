import { useAtom } from "jotai";
import {
  currentSessionIdAtom,
  videoStreamsAtom,
  audioStreamsAtom,
  onlinesAtom,
  currentSpaceAtom,
  currentUserAtom,
} from "../jotai/atoms";
import { useCallback } from "react";
import amplitude from "amplitude-js";
import { BlockType, SlideType } from "../types";
import { BOT_NAME } from "../consts";
import { amplitudeSetUser, amplitudeSetUserProperties } from "./amplitude";

type SlideCreatedEvent = {
  name: "slideCreated";
  type: SlideType;
  dropped?: boolean;
  screenshot?: boolean;
  pasted?: boolean;
};
type BlockCreatedEvent = {
  name: "blockCreated";
  type: BlockType;
  pasted?: boolean;
  dropped?: boolean;
};
type LoginOrSignUpEvent = {
  name: "signUp" | "login";
  userId: string;
};
type GenericAnalyticEvent = {
  name:
    | "spacesIndexLoaded"
    | "spaceCreated"
    | "spaceLoaded"
    | "activeSpacePing"
    | "exportedBlocksAsJSON"
    | "exportedBlocksAsText"
    | "experimentsPanelOpened"
    | "faceStamped"
    | "contextMenuOpened"
    | "slideDeleted"
    | "signUp"
    | "login"
    | "homeCreateSpaceClicked"
    | "audioTurnedOn"
    | "audioTurnedOff"
    | "videoTurnedOff"
    | "videoTurnedOn"
    | "chatMessageSent"
    | "showAllTemplates"
    | "templateImport"
    | "shareButtonClicked";
  [key: string]: string | number | boolean | undefined;
};

export type AnalyticEvent =
  | BlockCreatedEvent
  | SlideCreatedEvent
  | LoginOrSignUpEvent
  | GenericAnalyticEvent;

function sendEvent(event: AnalyticEvent) {
  const { name, ...data } = event;
  // Easy analytics debug
  // console.log(name, data);
  window.gtag("event", name, data);
  amplitude.getInstance().logEvent(name, data);
}

export function useSendAnalyticsEvent() {
  const [currentUser] = useAtom(currentUserAtom);
  const [currentSpace] = useAtom(currentSpaceAtom);
  const [currentSessionId] = useAtom(currentSessionIdAtom);
  const [onlines] = useAtom(onlinesAtom);
  const [videos] = useAtom(videoStreamsAtom);
  const [audios] = useAtom(audioStreamsAtom);

  return useCallback(
    (userEvent: AnalyticEvent) => {
      switch (userEvent.name) {
        case "spacesIndexLoaded":
          if (!currentUser) return;
          sendEvent({
            ...userEvent,
            userId: currentUser.id,
          });
          break;

        case "homeCreateSpaceClicked":
          sendEvent({
            ...userEvent,
            userId: currentUser ? currentUser.id : "anonymous",
          });
          break;

        case "signUp":
        case "login":
          amplitudeSetUser(userEvent.userId as string);
          amplitudeSetUserProperties({ anonymous: false });
          sendEvent({
            ...userEvent,
          });
          break;

        default:
          if (!currentSessionId || !currentSpace || !currentUser) return;

          const sessionInfo = {
            userId: currentUser.id,
            sessionId: currentSessionId,
            spaceId: currentSpace.id,
            participants: onlines.size,
            video: !!videos[currentSessionId],
            audio: !!audios[currentSessionId],
          };

          // Do not send any analytic events from screenshot bot
          if (currentUser.name === BOT_NAME) return;

          sendEvent({
            ...userEvent,
            ...sessionInfo,
          });
          break;
      }
    },
    [currentUser, currentSpace, currentSessionId, onlines, videos, audios]
  );
}
