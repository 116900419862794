import { useRouter } from "next/router";
import { generatenewSpaceId } from "../utils/generatenewSpaceId";
import { styled } from "../stitches.config";
import { useSendAnalyticsEvent } from "../analytics/events";

function App() {
  const router = useRouter();
  const sendAnalyticsEvent = useSendAnalyticsEvent();
  // TODO check meta tags
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundImage: `url("/static/landing-illustration.png")`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "1538px 1058px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
          textAlign: "center",
          background: "white",
          maxWidth: 480,
          width: "100%",
          height: 320,
          borderRadius: 16,
          justifyContent: "center",
        }}
      >
        <div
          style={{
            fontSize: 24,
            fontWeight: 600,
          }}
        >
          Cards
        </div>
        <div>
          <CreateButton
            style={{
              display: "inline-block",
              padding: 8,
              boxShadow: "inset 0px -2px 0px rgba(113, 81, 78, 0.1)",
              borderRadius: 4,
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => {
              sendAnalyticsEvent({ name: "homeCreateSpaceClicked" });
              const newSpaceId = generatenewSpaceId();
              const location = "/space/" + newSpaceId;
              router.push(location);
            }}
          >
            Create space
          </CreateButton>
        </div>
      </div>
    </div>
  );
}

const CreateButton = styled("button", {
  background: "$teal90",
  transition: "background 0.1s",
  ["&:hover"]: {
    background: "rgba(169, 233, 234, 1)",
  },
});

export default App;
